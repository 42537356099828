.ticket {
	display: flex;
	position: relative;
	flex-direction: row;
	width: 25rem;
	height: 21.25rem;
	margin: 0.5rem 0.2rem;
	background-color: white;
	border: 2px solid #000000;
	/* overflow: hidden; */
}

.side {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 2.83rem;
	height: 21.25rem;
	border-right: none;
	border-right: 2px solid black;
	overflow: hidden;
}

.sideText {
	white-space: nowrap;
	transform: rotate(-90deg);
	font-size: 0.9rem;
}

.mainContent {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 22.18rem;
	height: 21.25rem;
	padding: 0 1rem;
}

.header {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}
.logoContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	margin-top: 3px;
}
.dateContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	font-size: 0.8rem;
	width: 100%;
}

.muteLogo {
	width: 3.125rem;
	border-right: 1px solid #000000;
}

.sponsorContainer {
	display: flex;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
	margin-left: 0.4rem;
}

.qrContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer {
	font-size: 0.6rem;
	display: flex;
	flex-direction: column;
}

.notValid {
	position: absolute;
	font-size: 0.6rem;
	text-orientation: mixed;
	white-space: nowrap;
	transform: rotate(-90deg);
	right: -3.43rem;
	top: 7.18rem;
}

.notValidZebra {
	position: absolute;
	text-orientation: mixed;
	white-space: nowrap;
	transform: rotate(-90deg);
	right: -0.5in;
	top: 1.1in;
}

.dateZebra {
	position: absolute;
	text-orientation: mixed;
	white-space: nowrap;
	transform: rotate(-90deg);
	left: -0.2in;
	top: 1.1in;
}

.dateRangeZebra {
	position: absolute;
	text-orientation: mixed;
	white-space: nowrap;
	transform: rotate(-90deg);
	left: -0.57in;
	top: 1.05in;
}

.ticketContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	padding-left: 30px;
}

/* IMPRIMIENDO */
.PTicket {
	width: 4.16in !important;
	height: 3.542in !important;
	margin-top: 0.083in !important;
	margin-left: 0.033in !important;
	margin-right: 0.033in !important;
}

.PSide {
	width: 0.472in !important;
	height: 3.542in !important;
}

.PMainContent {
	width: 3.697in !important;
	height: 3.542in !important;
	padding: 0 0.167in !important;
}

.PMuteLogo {
	width: 0.521in !important;
}

.PSponsorContainer {
	width: 0.521in !important;
	height: 0.521in !important;
}
@media screen {
	/* .zebraTicket { */
	/* width: 50mm;
		height: 80mm;
		max-width: 50mm;
		background-color: transparent;
		border: none;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-content: center;
		box-sizing: border-box;
		overflow: hidden;
		margin: 0;
		padding: 0; */
	/* } */
}

@media print {
	html {
		width: 50mm;
		height: 80mm;
		max-width: 50mm;
	}
	body {
		width: 100%;
		height: 100%;
	}
	body,
	html {
		margin: 0;
		padding: 0;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
