.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media print {
	@page {
		size: 50mm 80mm;
		margin: 0;
	}

	#changeAccessStatus {
		visibility: hidden !important;
		display: none !important;
	}

	#print-only,
	#print-only * {
		visibility: visible !important;
	}

	#print-only {
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		page-break-inside: avoid;
	}

	/* body * {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		visibility: hidden;
	} */
}
/* 
@media print {

	body * {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		visibility: hidden;
		display: none;
	}

	#zebra-access-container {
		display: none;
	}

	#print-only,
	#print-only * {
		visibility: visible !important;
	}

	#print-only {
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		page-break-inside: avoid;
		background-color: red;
	}

	#print-only:last-child {
		page-break-before: avoid !important;
		break-before: avoid !important;
	}

	#changeAccessStatus {
		visibility: hidden;
	}
}

@media screen {
	#print-only,
	#print-only * {
		visibility: hidden;
		display: none;
	}
} */
