@import '~@fontsource/roboto/index.css';
@import '~@fontsource/material-icons/index.css';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* @media print {
	body * {
		visibility: hidden;
	}

	#accessToPrint,
	#accessToPrint * {
		visibility: visible;
		page-break-inside: avoid;
	}

	#accessToPrint {
		display: flex;
		flex-direction: row;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		page-break-inside: avoid;
	}

	#changeAccessStatus {
		visibility: hidden;
	}
} */
