.colorInput {
	display: flex;
	flex-direction: column;
	border: 1px solid rgb(199, 199, 199);
	border-radius: 4px;
	margin-bottom: 24px;
	padding: 16.5px 14px;
	padding-bottom: 4px;
	color: rgb(97, 97, 97);
}

.colorInput:hover {
	border: 1px solid black;
}

.colorInputHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.colorsContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	gap: 0.5rem;
	margin-bottom: 1rem;
	flex-wrap: wrap;
}

.colorItem {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 100%;
	border: 1px solid black;
}

.colorItem:hover {
	cursor: pointer;
}

.sizeItem {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
	border: 1px solid back;
	font-size: 0.8rem;
}

.sizeItem:hover {
	cursor: pointer;
}
